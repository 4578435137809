import Vue from "vue"
import Axios from 'axios'
import API from '../../config/development'
import { data } from "jquery"
let cancelToken
let execCancelToken

const state = {
    tabledata: '',
    tablerowdata: '',
    varialbeContent: '',
    messageContent:'',
    campaigncontentData:'',
    total_records:'',
    execTableData:'',
    execTotalRecords:'',
    campaignsPerfData:[],
    filterData: {},
    campType: '',
    reviewMode: false,
}

const getters = {
    getTabledata: state => state.tabledata,
    getTablerowdata: state => state.tablerowdata,
    getVariableContent: state => state.varialbeContent,
    getMessageContent: state => state.messageContent,
    getcampaignData: state => state.campaignData,
    getcampaigncontentData: state => state.campaigncontentData,
    getcampaignUploadType: state => state.campaignUploadType,
    getExecTabledata: state => state.execTableData,
    performanceData: state => state.campaignsPerfData,
    getFilterData: state => state.filterData,
    getCampType: state => state.campType,
    getReviewMode: state => state.reviewMode,
}

const mutations = {
    SET_TABLEDATA: (state, payload) => {
        state.tabledata = payload.result
        state.tablerowdata = payload.rowsCount
        state.total_records = payload.totalCount
        state.filterData = payload.filterData
    },
    SET_EXEC_TABLEDATA: (state, payload) => {
        state.execTableData = payload.result
        state.execTotalRecords = payload.totalCount
        state.filterData = payload.filterData
    },
    SET_CAMP_PERF_DATA: (state, payload)=>{
        state.campaignsPerfData = payload
    },
    ADD_VARIABLES_UPLOAD: (state, payload) => {
        state.varialbeContent = payload
    },
    ADD_CONTENT_UPLOAD: (state, payload) => {
        state.messageContent = payload
    },
    ADD_COMPAIGN_DATA: (state, payload) => {
        state.campaignData = payload
    },
    ADD_COMPAIGN_CONTENT_DATA: (state, payload) => {
        state.campaigncontentData = payload
    },
    UPLOAD_FILE_TYPE: (state, payload) => {
        state.campaignUploadType = payload
    },
    SET_CAMP_TYPE: (state, payload) => {
        state.campType = payload
    },
    SET_REVIEW_MODE: (state, payload) => {
        state.reviewMode = payload
    }
}

const actions = {

    async cancelCampaign({ commit },payload) {
        console.log(payload)
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/cancel-adhoc-campaign`,{Id:payload.Id,isCampaignInstance:payload.isCampaignInstance
            },{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
        } catch (error) {
            console.log(error)

        }
    },
    async pauseCampaign({ commit }, payload) {
        console.log(payload)
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/pause-adhoc-campaign`,{Id:payload.Id,isCampaignInstance:payload.isCampaignInstance
            },{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
        } catch (error) {
            console.log(error)
        }
    },
    async resumeCampaign({ commit }, payload) {
        console.log(payload)
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/resume-adhoc-campaign`,{Id:payload.Id,isCampaignInstance:payload.isCampaignInstance
            },{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
        } catch (error) {
            console.log(error)
        }
    },
    async getCampPerfData({commit}, data){
        // console.log(data);
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = Axios.CancelToken.source()
        try{
            const resp = await this.$axios.post(`${API.Backend_URL}/get-campaign-perf-data`,data, {
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                },
                cancelToken: cancelToken.token
            })
            // console.log(resp.data);
            commit('SET_CAMP_PERF_DATA',resp.data)
            return resp.data.records;
        }catch(error){
            console.log(error)
        } 
        // source.cancel(
        //     'Request canceled!' // optional
        //   );
    },
    async getCampaignlist({ commit },reqData) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try {
            // console.log(reqData)
            const resp = await this.$axios.post(`${API.Backend_URL}/get-campaign-list`,reqData,{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            if(resp.data.result.length > 0) {
                resp.data.result.forEach(r => {
                    if(!r.approvalStatus) {
                        r.approvalStatus = 'drafted';
                    }
                });
            }
            commit('SET_TABLEDATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },

    async getApprovalCampaignlist({ commit },reqData) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try {
            // console.log(reqData)
            const resp = await Vue.axios.post(`${API.Backend_URL}/get-approvals-list`,reqData,{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            commit('SET_TABLEDATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },

    async getCampaignReports({ commit }, payload) {
        // console.log(payload);
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        if (typeof execCancelToken != typeof undefined) {
            execCancelToken.cancel("Operation canceled due to new request.")
        }
        execCancelToken = Axios.CancelToken.source()
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-exec-campaign-list`,payload,{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            commit('SET_EXEC_TABLEDATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },

    async getStoredContacts({ commit }) {
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-stored-contacts`,{
                userid:localStorage.getItem("Loginuserid")
            },{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            return resp;
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },

    async getstoreVariableHeaders({ commit },campaignVariableheaders) {
        commit('ADD_VARIABLES_UPLOAD', campaignVariableheaders)
    },

    async getstoreContentMessage({ commit },campaignContent) {
        commit('ADD_CONTENT_UPLOAD', campaignContent)
    },

    async getstoreCreatecompaigndata({commit},campaignData) {
        commit('LOADING_SPINNER_SHOW_MUTATION', true,{root:true})
        try{
            let compaignData = new Promise( (resolve,reject) => {
                this.$axios.post(`${API.Backend_URL}/get-camp-data`, {"campId":campaignData, reviewMode: state.reviewMode},{
                headers: { 
                  'Content-Type': 'application/json',
                  "authorization":localStorage.getItem('Accesstoken')
                }
                }).then( (responseCampprogress) => {
                  resolve(responseCampprogress.data)
                });
              })
              let getCamp = await compaignData
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
            return getCamp.data
        }catch (error) {
            console.log(error)
            // commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },

    async getstoreCreatecontentdata({commit},contentData){
        commit('ADD_COMPAIGN_CONTENT_DATA', contentData)
    },

    async getstoreFileuploadedType({commit},uploadType){
        commit('UPLOAD_FILE_TYPE', uploadType)
    },
    async getsendtextsms({commit},smscontent){
        try {
        const resp = await this.$axios.post(`${API.Backend_URL}/test-channel`, {'channel': 'sms', "smsData":smscontent.smsdata,"contentData":smscontent.content_data},{
            headers : {
                'Content-Type': 'application/json',
                "authorization":localStorage.getItem('Accesstoken')
            }
        })
        return resp;
        } catch (error) {
            console.log(error)
        }
    },
    async getCreateSchedule({ commit,dispatch }, scheduledata) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try {
        const resp = await this.$axios.post(`${API.Backend_URL}/update-schedule-campaign`, scheduledata,{
            headers : {
                "authorization":localStorage.getItem('Accesstoken')
            }
        })
        if (resp.data.status == true) {
            return resp;
        }
        } catch (error) {
            console.log(error)
        }
    },
    async getcampaignpreviewlist({commit, rootState},typeData) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try{
            let gettotalrows = await this.$axios.post(`${API.Backend_URL}/get-file-count`,{
                'fileid':typeData.feild[0]
            },{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            let rowsresponse = gettotalrows.data
            const compUUID = rootState.Auth.User.user.uuid
            const previewUrl = `${API.Backend_URL}/get-preview-records?cid=${compUUID}&uploadId=${typeData.feild[0]}&type=${typeData.type}&se=${rowsresponse.createdAt}&limit=5&total=${rowsresponse.result}`
            let previewData = await this.$axios.post(previewUrl, {
                responseType: 'json'
            })
            return previewData
        } catch(errors){
            console.log(errors)
        }
    },
    async getfirstpreviewrecord({commit, rootState},payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try{
            const compUUID = rootState.Auth.User.user.uuid
            const previewUrl = `${API.Backend_URL}/get-preview-records?cid=${compUUID}&uploadId=${payload.fileids[0].id}&type=first&se=${payload.fileids[0].createdAt}&limit=1`
            let previewData = await this.$axios.post(previewUrl, {
                responseType: 'json'
            })
            return previewData
        } catch(errors){
            console.log(errors)
        }
    },
    async getlanguagecode({commit, rootState},payload) {
        try {
        const resp = await this.$axios.post(`${API.Backend_URL}/detect-language`, payload,{
            headers : {
                "authorization":localStorage.getItem('Accesstoken')
            }
        })
        return resp;
        } catch (error) {
            console.log(error)
        }
    },
    async getNearestDltTemplate({ commit }, payload) {
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-dlt-match`, payload)
            return resp.data
        } catch (error) {
            console.log(error)
        }
    },
    async getMessageByDLT({ commit }, payload) {
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-dlt-message`, payload)
            return resp.data
        } catch (error) {
            console.log(error)
        }
    },
    async getloadfilestatus({ commit,dispatch }, fileIds) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try {
        
        let responstatus = await this.$axios.post(`${API.Backend_URL}/get-status`, {fileIds:fileIds},{
            headers : {
                "authorization":localStorage.getItem('Accesstoken')
            }
        }).then( (response) => {
            return response;
        })
        return responstatus;
        } catch (error) {
            console.log(error)
        }
    },

    async getuploadDocuments({commit},data,configheader){
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try{
            return await this.$axios.post(`${API.Backend_URL}/upload-cammpaign-files`, data, configheader)
            .then( async (response) => {
                return response
            })
        } catch(error){
            console.log(error)
        }
    },

    async StoreCampaigndata({commit},data){
        commit('LOADING_SPINNER_SHOW_MUTATION', true,{root:true})
        try{
            const resp = await this.$axios.post(`${API.Backend_URL}/create-campaign`, data, {
                headers : {
                    "authorization": localStorage.getItem('Accesstoken')
                }
            })
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
            return resp.data
        } catch(error){
            console.log("StoreCampaigndata error ",error)
            // commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },

    async ContentCampaign({commit},data){
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try{
            const resp = await this.$axios.post(`${API.Backend_URL}/create-campaign`, data, {headers: {
                "Content-Type": "application/json",
                "authorization":localStorage.getItem('Accesstoken')
            }})
            return resp.data
        } catch(error){
            console.log(error)
        }
    },

    async Campfileprogress({commit},payload){
        try{
            return await this.$axios.post(`${API.Backend_URL}/get-camp-file-status`,  payload, {headers: {
                "Content-Type": "application/json",
                "authorization":localStorage.getItem('Accesstoken')
              }})
            .then( async (response) => {
                return response
            })
        } catch(error){
            console.log(error)
        }
    },

    async allLinksData({commit}){
        commit('LOADING_SPINNER_SHOW_MUTATION', true,{root:true})
        try{
            const resp = await this.$axios.post(`${API.Backend_URL}/all-links-data`, {
                headers : {
                    "authorization": localStorage.getItem('Accesstoken')
                }
            })
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
            return resp.data
        } catch(error){
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },
    async getBaseLineConfig({commit}, payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', true,{root:true})
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/baseline/get-current-baseline`,payload, {
                headers : {
                    "authorization": localStorage.getItem('Accesstoken')
                }
            })
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
            return resp.data.result
        }catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },
    async getInsightMetrics({commit}, payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', true,{root:true})
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-upload-insight-metrics`,payload, {
                headers : {
                    "authorization": localStorage.getItem('Accesstoken')
                }
            })
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
            return resp.data
        }catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },
    async getInsightCampaignVolume ({commit}, payload) {
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-insight-campaign-volume`, payload, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            if (resp.data.campaignVolume) {
                return resp.data.campaignVolume
            }
            return ''
        } catch (err) {
            console.log(`Error getting campaign volume ${err}`)
            return ''
        }
    },
    async getReachabilityVolume ({commit}, payload) {
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-channel-avail-campaign-volume`, payload, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            if (resp.data.campaignVolume) {
                return resp.data.campaignVolume
            }
            return ''
        } catch (err) {
            console.log(`Error getting campaign volume ${err}`)
            return ''
        }
    },
    async uploadMedia ({ commit }, payload) {
        const formData = new FormData();
        formData.append('file', payload.file);
        formData.append('header', JSON.stringify(payload.header));
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/upload-media`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return { data: resp.data, success: true, status: resp.status }
        } catch (error) {
            return { success: false }
        }
    },
    async getMediaStatus ({commit}, payload) {
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-media-status`, payload, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            return resp.data;
        } catch (err) {
            console.log(`Error getting media status`)
            return ''
        }
    },
    async downloadMedia ({commit}, payload) {
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/media-download`, payload, { responseType: 'blob' });
            const blob = new Blob([resp.data]);
            const url = window.URL.createObjectURL(blob);
            return url;
        } catch (err) {
            console.log(`Error in downloading Media`)
            return ''
        }
    },
    async getMediaById ({commit}, payload) {
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-media-by-id`, payload, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            return resp.data;
        } catch (err) {
            console.log(`Error getting media status`)
            return ''
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
